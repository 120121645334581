import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ITaskFormInitialValue } from "../../model/task";
import { taskInitialValue } from "../../utility/formInitialValue";

export const localTaskSlice = createSlice({
  name: "localCustomerContact",
  initialState: taskInitialValue,
  reducers: {
    saveTaskInfoToLocal: (
      state,
      action: PayloadAction<ITaskFormInitialValue>
    ) => {
      return action.payload;
    },
  },
});

export const { saveTaskInfoToLocal } =
  localTaskSlice.actions;

export default localTaskSlice.reducer;