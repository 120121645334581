import {
  createTaskDetails,
  fetchTaskDetails,
  fetchTaskList,
  postTask,
  updateTaskDetails,
} from "../task";

import { ITask } from "../../model/task";
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { createdByInitialState } from "../../utility/initialStateForReducer";

const initialState: ITask = {
  taskListLoading: false,
  taskDetailsLoading: false,
  taskListData: {
    totalPage: 0,
    data: [],
  },

  taskPostData: {
    id: "",
  },
  taskPostError: "",
  taskListError: "",
  taskDetailsError: "",
  taskDetailsData: {
    tableName: "",
    recordId: "",
    dueOnTimestamp: "",
    assignedToEmployeeId: "",
    title: "",
    description: "",
    isCompleted: false,
    isDeleted: false,
    assignedToEmployee: {
      id: "",
      firstName: "",
      lastName: "",
    },
    dueTime: "",
    createdBy: createdByInitialState,
    createdAt: "",
  },
  taskPostLoading: false,
};

// create reducer for your task details
export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    clearAllError(state, action) {
      return {
        ...state,
        taskPostError: "",
        taskDetailsError: "",
        taskListError: "",
      };
    },
    resetTask(state, action) {
      return {
        ...state,
        taskPostData: {
          id: "",
        },
      };
    },
  },
  extraReducers: (Builder) => {
    Builder.addCase(fetchTaskList.pending, (state, action) => {
      return {
        ...state,
        taskListLoading: true,
      };
    });
    Builder.addCase(fetchTaskList.fulfilled, (state, action) => {
      return {
        ...state,
        taskListLoading: false,
        taskListData: action.payload,
      };
    });
    Builder.addCase(fetchTaskList.rejected, (state, action) => {
      return {
        ...state,
        taskListLoading: false,
        taskListError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });
    Builder.addCase(postTask.pending, (state) => {
      return {
        ...state,
        taskPostLoading: true,
      };
    });
    Builder.addCase(postTask.fulfilled, (state, action) => {
      return {
        ...state,
        taskPostLoading: false,
        taskPostData: action.payload,
      };
    });
    Builder.addCase(postTask.rejected, (state, action) => {
      return {
        ...state,
        taskPostLoading: false,
        taskPostError: action.payload
          ? action.payload
          : "OOPS! Internal server error",
      };
    });
    Builder.addCase(fetchTaskDetails.pending, (state) => {
      return {
        ...state,
        taskDetailsLoading: true,
      };
    });
    Builder.addCase(fetchTaskDetails.fulfilled, (state, action) => {
      return {
        ...state,
        taskDetailsLoading: false,
        taskDetailsData: action.payload,
      };
    });
    Builder.addCase(fetchTaskDetails.rejected, (state, action) => {
      return {
        ...state,
        taskDetailsLoading: false,
        taskDetailsError: action?.payload || "OOPS! Internal server error",
      };
    });
  },
});

export const { clearAllError, resetTask } = taskSlice.actions;
export const taskReducer = taskSlice.reducer;
